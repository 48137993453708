.about-us-l-t-r-i {
    font-size: 48px;
    margin-top: 50px;
  }
  
  .about-us-l-t-r-i span {
    color: #D82927;
  }
  @media screen and (max-width:756px){
    .about-us-l-t-r-i{
    font-size:28px;
    }
    .left-text-right-image-content{
      padding-right:0;
      /* padding-left: 20px; */
    }
    .about-us-main .left-text-right-image-content{
      /* padding-right:0; */
      padding-left: 20px;
    }
  }
  .about-black-bg-text {
    text-align: center;
    color: white;
    background-image: url("/public/images/B.svg");
    background-repeat: no-repeat;
    background-size: auto;
    background-position: bottom;
    margin-top: 40px;
  }
  @media screen and (max-width:950px){
    .about-black-bg-text{
      background:url("/public/images/black.webp");
      background-repeat: no-repeat;
        background-position: center;
    }
  }
  
  /* @media screen and (max-width:1150px){
    .about-black-bg-text{
      background-repeat: repeat;
    background-size: cover;
    background-position: bottom;
    }
  } */
  .about-black-bg-text-h3 {
    font-size: 40px;
    text-align: center;
    font-weight: 700;
    color: #D82927;
    position: relative;
    width: max-content;
    padding-top:40px;
  }
  .about-black-bg-text-h3:before {
    content: "";
    position: absolute;
    left: 5px;
    bottom: -8px;
    width: 50%;
    border-bottom: 4px solid #D82927;
    border-radius: 10px;
  }
  
  .about-black-bg-text p {
    font-size: 15px;
    max-width:1300px;
    margin:0 auto;
    line-height:22.5px;
    padding: 20px 50px;
  }
  @media screen and (max-width:756px){
    .about-black-bg-text-h3{
      font-size:25px;
      padding-top:10px;
    }
    .about-black-bg-text p{
      padding: 20px 20px;;
    }
  }
  .left-person-right-heading-subheading{
    display: flex;
      align-items: center;
      gap: 40px;
      padding-top:50px;
      /* margin: 40px 0px; */
  }
  .right-person-left-heading-subheading{
    display: flex;
    flex-direction: row-reverse;
    align-items: center;
    gap: 40px;
    /* margin: 40px 0px; */
    padding-bottom:50px;
    padding-top:50px;
  }
  .person-with-name{
    background-color: rgba(247, 247, 247, 1);
    border-radius:15px;
    box-shadow: 0px -1px 34px 0px #00000021;
  
  }
  .person-with-name img{
    max-width:400px;
  }
  .person-name{
    text-align: center;
    font-size: 24px;
    font-family: poppins;
    padding-top:10px;
    /* font-weight: 500; */
  }
  .person-position{
    text-align: center;
    font-size: 20px;
    font-family: poppins;
    padding-bottom: 10px;
    /* font-weight: 500; */
  }
  .p-h-s-h{
    font-size: 30px;
    font-family: poppins;
    font-weight:700;
    position: relative;
    color:#D82927;
    width: max-content;
  }
  .p-h-s-h:before{
    content: "";
      position: absolute;
      left: 2px;
      bottom: -3px;
      width: 35%;
      border-bottom: 4px solid #D82927;
      border-radius: 10px;
  }
  
  .p-h-s-s{
    font-size: 48px;
    font-family: poppins;
    font-weight:700;
    padding-top:20px;
    line-height:1.2;
  }
  @media screen and (max-width:900px){
    .left-person-right-heading-subheading{
      flex-wrap: wrap;
      justify-content: center;
      flex-direction: column-reverse;
      padding-top: 50px !important;
          padding-bottom: 50px !important;
    }
    .right-person-left-heading-subheading{
      flex-wrap: wrap;
      justify-content: center;
      flex-direction: column-reverse;
      padding-top: 50px !important;
          padding-bottom: 50px !important;
    }
    .p-h-s-s{
      font-size: 30px;
    }
    .p-h-s-h{
      font-size: 20px;
    }
    .person-name{
  font-size: 20px;;
    }
  
    .person-position{
  font-size: 16px;
    }
    .person-with-name img{
      max-width:300px;
      width:100%;
    }
  }
  .about-us-l-i-r-t-main {
    margin-top: 50px;
    margin-bottom: 100px;
  }
  
  .about-l-i-r-t h3 {
    font-size: 16px;
    color: #D82927;
  }
  
  .about-l-i-r-t h2 {
    font-size: 45px;
    line-height: 50px;
    padding: 5px 0px 20px 0px;
  }
  
  .about-l-i-r-t h2 span {
    color: #D82927;
  }
  
  .about-us-qlty-detail {
    display: flex;
    margin-top: 50px;
    /* grid-template-columns: 1fr 1fr; */
    align-items: end;
    justify-content: space-between;
    gap:100px;
    border-bottom: 1px solid #EBEBEB;
      padding-bottom: 40px;
  }
  
  @media screen and (max-width:950px) {
    .about-us-qlty-detail {
      flex-wrap: wrap;
      justify-content: center;
      gap:10px;
    }
  
    .about-us-qlty-detail-right {
      display: flex;
      justify-content: center;
      padding: 30px 0px 25px 0px;
    }
  }
  
  .about-us-qlty-detail-left h2 {
    font-size: 45px;
    padding-bottom: 10px;
  }
  
  @media screen and (max-width:756px){
    .about-us-qlty-detail-left h2{
      font-size:28px;
    }
  }
  .about-us-qlty-detail-left h2 span {
    color: #D82927;
  }
  .quality-points p{
    line-height:1.5;
  }
  .about-us-quality-ul li p{
    line-height:1.5;
  }
  .quality-points div {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 10px;
    max-width: 500px;
    padding: 20px 0px;
  }
  
  .quality-points div h3 {
    font-size: 16px;
  }
  
  br {
    padding-top: 100px;
  }
  