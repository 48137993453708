.Navbar-jc{
    position: fixed;
    width: 100%;
    z-index: 9999999;
    background-color: white;
    display: flex;
    justify-content: space-between;
    /* max-width: 1200px; */
    align-items: center;
    margin: 0 auto !important;
    padding:0 2rem;
    padding-top:12px;
}
.nav-link-li.dropdown {
    position: relative; /* For absolute positioning of dropdown content */
}

.dropdown-content {
    display: none;
    position: absolute;
    background-color: rgba(218, 54, 52, 1);
    min-width: 160px;
    box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
    z-index: 1;
    /* right: -25px; */
    border-radius: 10px;
    /* top: 30px; */

}

.dropdown-content li {
    color: white; /* Dropdown item text color */
    padding: 12px 16px; /* Dropdown item padding */
    text-decoration: none; /* No underline on text */
    display: block; /* Make each item a block to fill the width */
    cursor: pointer; /* Change mouse cursor on hover */
    font-weight:14px;
}

.dropdown-content li:hover .dropdown-content li a{
    background-color: #f1f1f1;
    color:#D82927 !important;
    border-radius: 10px; /* Hover color change for dropdown items */
}



/* Show dropdown content on hover or click */
.nav-link-li.dropdown:hover .dropdown-content,
.nav-link-li.dropdown .dropdown-content.show {
    display: block;
}
.nav-link-li-mobile.dropdown:hover .dropdown-content,
.nav-link-li.dropdown .dropdown-content.show {
    display: block;
}
.logo-box img{
    /* width:100px; */
    height:75px;
    cursor:pointer;
}
.nav-link-box{
    width:100%;
    padding-top: 15px;
}
.nav-link-ul{
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 7%;
    list-style: none;
}
.nav-link-li{
    font-family: inter;
    font-size: 16px;
    font-weight: 500;
    text-transform: capitalize;
    color: rgba(110, 110, 110, 1);
    text-decoration:none;
}
.dropdown-icon {
    display: inline-block;
    margin-left: 5px;
    transition: transform 0.3s ease; /* Smooth transition for rotation */
}

/* Rotate icon when active or on hover */
.nav-link-li.dropdown:hover .dropdown-icon,
.nav-link-li.dropdown.active-dropdown .dropdown-icon {
    transform: rotate(180deg); /* Rotate the icon */
}

.nav-link-ul li a:hover{
    color: #D82927;
}

@media (max-width: 900px) {
    .nav-link-box {
        display: none;
    }

    .hamburger {
        display: block;
        cursor: pointer;
    }
}

@media (min-width: 901px) {
    .hamburger {
        display: none;
    }
}

.drawer {
    position: fixed;
    top: 0;
    right: 0;
    width: 250px;
    height: 100vh;
    background: white;
    box-shadow: -2px 0 8px rgba(0,0,0,0.1);
    transform: translateX(100%);
    transition: transform 0.3s ease-in-out;
}

.drawer.open {
    transform: translateX(0);
    z-index:10;
    padding-top: 35px;
}
.nav-wrapper{
    display: flex;
    justify-content: space-between;
    align-items: center;
    max-width: 1200px;
    width: 100%;
    margin: auto;
}
.nav-link-ul-mobile{
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: start;
    margin-top: 50px;
    gap: 20px;
    margin-left: 15px;
    list-style: none;
    /* position: absolute; */
}
.hamburger{
    font-size: 25px;
}
.hamburger-x{
    float: right;
    margin: 10px;
    font-size: 18px;
}
.hamburger-x img{
    width: 20px;
}

.active-dropdown{
    color:#D82927;
    position: relative;
}
.active{
    color:#D82927;
    position: relative;
}
.active:before {
    content: "";
    position: absolute;
    left: 0px;
    bottom: -6px;
    width: 0%; /* Start with width 0% */
    border-bottom: 2px solid #D82927;
    transition: width 1s ease-in-out;
}
.active:hover:before {
    width: 75%; /* Animate to 75% width */
}

.dropdown-content li a{
    text-decoration:none;
    color:white;
}
.dropdown-content li:hover a{
color:#D82927 !important;
}
.dropdown-content  :hover{
    background-color: white;
    border-radius: 10px;
}