.left-content-right-image-main {
    margin-top: 100px !important;
    border-radius: 25px;
    display: grid;
    grid-template-columns: 1.2fr 0.8fr;
    /* box-shadow: 2px 2px 2px black; */
    background-color: #D82927;
    align-items: center;
    column-gap: 20px;
    margin: 40px 0px;
  }
  
  .lcri-content {
    padding: 30px 0px 30px 60px;
    color: white;
  }
  
  .lcri-content h3 {
    font-size: 30px;
    font-weight: 700;
    margin: 0;
    padding-bottom: 10px;
  }
  
  .lcri-content p {
    font-size: 14px;
    font-weight: 500;
    margin: 0;
    padding-bottom: 10px;
  }
  
  .lcri-content button {
    border: none;
    background-color: white;
    padding: 10px 20px;
    border-radius: 15px;
    margin-top: 30px;
    box-shadow: 0px 4px 4px 0px #00000040;
  
  }
  
  .lcri-content button a {
    color: #D82927;
    font-size: 14px;
    font-weight: 600;
    text-decoration: none;
  }
  .lcri-content button:hover a{
  color: #ffffff;
  }
  .lcri-content button:hover{
    background-color: #D82927;
    outline: 1px solid #ffffff;
  }
  .lcri-image {
    height: 100%;
    width: 100%;
    font-size: 0;
  }
  
  .lcri-image img {
    height: 100%;
    width: 100%;
    max-height: 400px;
    object-fit: cover;
    border-radius: 0px 25px 25px 0px;
  }
  
  @media screen and (max-width:800px) {
    .left-content-right-image-main {
      display: flex;
      flex-direction: column-reverse;
    }
  
    .lcri-image img {
      border-radius: 25px 25px 0px 0px;
    }
  
    .lcri-content {
      padding: 30px;
    }
  }
  