* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
  /* overflow:hidden; */
  /* line-height:1.7rem; */
}
button , a , li{
  cursor:pointer;
}

.page-width {
  max-width: 1300px;
  padding: 0 4rem;
  margin: 0 auto;
}

@media screen and (max-width:750px) {
  .page-width {
    padding: 0px 2rem !important;
  }
}

.App {
  font-family: inter !important;
  overflow-x:hidden;
  /* display: flex;
    flex-direction: column; */
    /* justify-content: center; */
    /* align-items: center; */
}



/* main-slider css */
.main-slider-box {
  /* margin-top:120px; */
}

.slider-card {
  display: grid;
  grid-template-columns: 1fr 2fr;
  align-items: center;
  max-width: 1200px;
  margin: 0 auto;
  background: #D82927;
  border-radius: 30px;
  justify-content: center;
  margin-top: 120px !important;
}

.slider-content-box {
  padding: 15px 60px;
  color: white;
  display: flex;
  flex-direction: column;
}

.slider-content-box h2 {
  font-size: 50px;
  font-weight: 700;
  font-family: 'Inter';
  margin: 0;
  text-align: start;
}

.slider-content-box p {
  padding-top: 10px;
  font-family: 'Inter';
  font-size: 14px;
  text-align: left;
  margin: 0;
  font-weight:400;
  line-height:1.5
}

.slider-content-box button {
  width: max-content;
  background: white;
  border: none;
  margin-top: 25px;
  padding: 10px 20px;
  border-radius: 20px;
  box-shadow: 0px 4px 4px 0px #00000040;

}
.main-slider-button{
  color:#D82927;
  font-weight: 600;;
}
.main-slider-button:hover {
  color:#ffffff !important;
  
}
.main-slider-button:hover{
 background-color: #D82927;
 outline:1px solid white;
}

.slider-content-box button a {
  color: #D82927;
  text-decoration: none;
  font-family: 'Inter';
  font-weight:600;
}

.slider-image-box {
  font-size: 0;
  width:100%;
}

.slider-image-box img {
  width: 100%;
  height: 450px;
  object-fit: cover;
  padding: 0;
  margin: 0;
  border-radius: 0px 30px 30px 0px;
  margin-left: 1px;
}


@media screen and (max-width:1000px) {
  .slider-content-box h2 {
    font-size: 30px;
  }

  .slider-content-box button {
    margin-top: 10px;
  }
}

@media screen and (max-width:750px) {
  .slider-card {
    display: flex;
    flex-direction: column-reverse;
    margin: 0;
  }

  .slider-content-box button {
    margin-top: 20px;
  }

  .slider-content-box {
    padding: 30px;
  }

  .slider-image-box img {
    border-radius: 30px 30px 0px 0px;
    height:300px;
    margin-left: 0px;
  }
}

.product-line-stanley-heading{
  width: 100%;
  text-align: center;
  margin:30px 0px;
}
.product-line-stanley-heading span{
  font-family: Poppins;
font-size: 40px;
font-weight: 700;
text-align: center;
color:#D82927;
position: relative;
text-transform: uppercase;
}


.product-line-stanley-heading span:after{
  content: "";
  position: absolute;
  left: 5px;
  bottom: -8px;
  width: 50%;
  border-bottom: 4px solid #D82927;
  border-radius: 10px;
}


.home-see-more-btn{
  width: max-content;
    margin-top: 10px;
    background: #D82927;
    border: none;
    padding: 10px 20px;
    border-radius: 20px;
    box-shadow: 0px 4px 4px 0px #00000040;
}
.home-see-more-btn:hover{
  background-color: white;
    outline: 1px solid #D82927;
}
.home-see-more-btn:hover a {
  color: #D82927;
}
.home-see-more-btn a{
  color: #FFFFFF;
    text-decoration: none;
    font-family: 'Inter';
    font-weight: 600;
    text-transform: uppercase;
}

/* 



/* //left-imageright-text css */
.left-text-right-image-main {
  display: grid;
  grid-template-columns: 2fr 1fr;
  /* grid-auto-rows: 100px; */
  gap: 40px;
  padding: 50px 30px 50px 50px;
  align-items: center;
}

.card-container-left {
  position: relative;
  width: 320px;
  height: 320px;
  border-radius: 40px;
  /* box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2); */
}

.red-card {

  width: 100%;
  height: 100%;
  background-color: red;
  z-index: 1;
  border-radius: 40px;
}

.left-image {
  position: absolute;
  top: 15px;
  right: 15px;
  width: 350px;
  height: 320px;
  z-index: 2;
  border-radius: 40px;
}

@media screen and (max-width:900px) {
  .left-image-right-text-main {
    padding: 50px 0px !important;
  }

  .card-container-left {
    width: 280px;
    height: 250px;
  }

  .left-image {
    width: 300px;
    height: 270px;
  }
}

@media screen and (max-width:750px) {
  .left-image-right-text-main {
    align-items: center;
    justify-content: center;
    display: flex !important;
    flex-direction: column;
    gap: 70px !important;
    
  }
}

.left-image-right-text-content {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.l-i-r-t-title {
  margin: 0;
  font-size: 20px;
  font-weight: 700;
  color: #D82927;
  margin: 0;
}

.l-i-r-t-heading {
  font-size: 25px;
  font-weight: 700;
  margin: 0;
  color: #303030;
}

.l-i-r-t-description {
  font-size: 14px;
  font-weight: 400;
  line-height: 22.5px;
  margin: 0;
}

.l-i-r-t-btn {
  width: max-content;
  margin-top: 10px;
  background: #D82927;
  border: none;
  padding: 10px 20px;
  border-radius: 20px;
  box-shadow: 0px 4px 4px 0px #00000040;

}

.l-i-r-t-btn a {
  color: #FFFFFF;
  text-decoration: none;
  font-family: 'Inter';
  font-weight: 600;
  text-transform: uppercase;
}

.l-i-r-t-btn:hover a{
  color: #D82927;
}
.l-i-r-t-btn:hover{
  background-color: white;
  outline:1px solid #D82927;
}


/* left-text-right-image */
.left-image-right-text-main {
  display: grid;
  grid-template-columns: 0.7fr 1.3fr;
  /* grid-auto-rows: 100px; */
  gap: 80px;
  padding: 50px 30px 50px 80px;
  align-items: center;
}

.card-container-left {
  position: relative;
  width: 320px;
  height: 320px;
  border-radius: 40px;
  /* box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2); */
}

.red-card {

  width: 100%;
  height: 100%;
  background-color: #D82927;
  z-index: 1;
  border-radius: 203.5px 203.5px 203.5px 0px;
}

 .left-image {
  position: absolute;
  top: 0px;
  right: -40px;
  width: 350px;
  height: 320px;
  object-fit: cover;
  z-index: 2;
  border-radius: 100% 100% 100% 0px;

  }
  .left-image-person{
    /* position: absolute; */
  /* top: 0px; */
  /* right: -40px; */
  width: 350px;
  height: 320px;
  object-fit: cover;
  /* z-index: 2; */
  border-radius: 20px;
  }
@media screen and (max-width:900px){
  .left-image-person{
    width: 280px;
        height: 250px;
  }
}
.left-text-right-image-content {
  display: flex;
  flex-direction: column;
  gap: 10px;
  padding-right: 30px;
}
.about-us-quality-ul li{
  padding-bottom:15px;
}
.about-us-quality-ul li::marker{
  color: #D82927;
}
.about-us-quality-ul li span{
  color:#D82927;
  font-weight:700;
  padding-bottom:5px;
  font-size:16px;
}
.left-person-right-heading{
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  margin:40px 0px;
 flex-wrap: wrap;
 gap:40px;
}
.right-reading-person-image{
  font-size: 48px;
  font-weight: 700;
}
.card-container-left img{
  margin-left:-40px;
}
@media screen and (max-width:900px) {
  .left-text-right-image-main {
    padding-top: 50px !important;
    padding-bottom: 50px !important;
    gap:20px !important;
  }

  .card-container-left img {
    width: 300px;
    height: 270px;
    margin: auto;
  }
  .card-container-left{
    width: 280px;
    height: 250px;
  }

  .left-image {
    width: 300px;
    height: 270px;
  }
  .red-card{
    width: 300px;
    height: 270px;
  }
  
  .right-reading-person-image{
    font-size: 36px;
}
}

@media screen and (max-width:750px) {
  .left-text-right-image-main {
    align-items: center;
    justify-content: center;
    display: flex;
    flex-direction: column-reverse;
    gap: 40px !important;
    padding-left:0px !important;
    padding-top:20px !important;
    padding-right:0px !important;
  }
}
.about-us-quality-ul{
  font-size:16px;
}
.about-us-quality-ul 
.left-text-right-image-content {
  padding-right: 0;
}


/* heading-with_productcard */


.heading-width-prdcard-main {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding-top: 50px;
  gap: 30px;
  border: 1px 0px 1px 0px solid grey;
}

.heading-width-prdcard-main-heading {
  font-size: 40px;
  text-align: center;
  font-weight: 700;
  color: #D82927;
  position: relative;
  width: max-content;
}

.heading-width-prdcard-main-heading:before {
  content: "";
  position: absolute;
  left: 5px;
  bottom: -8px;
  width: 35%;
  border-bottom: 4px solid #D82927;
  border-radius: 10px;
}
@media screen and (max-width:756px){
  .heading-width-prdcard-main-heading{
    font-size:25px;
  }
}

.product-list {
  padding-top: 25px;
  display: flex;
  width: 100%;
  justify-content: space-between;
 row-gap:50px;
  flex-wrap: wrap;
}

.hwpcm-button {
  background-color: #D82927;
  border-radius: 10px;
  border: none;
  width: max-content;
  padding: 10px 25px;
}

.hwpcm-button a {
  color: white;
  text-decoration: none;
  font-size: 14px;
  font-weight: 600;
}

.our-products-box {
  padding-top: 75px;
}
@media screen and (max-width:756px){
  .our-products-box {
    padding-top: 40px;
  }
}

/* product-card */
.prd-card-main {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 20px;
}

.prd-card-main:hover .prd-card-overlay {
  visibility: visible;
  opacity: 1;
  transform: translateY(0); /* Move to original position */
}

.prd-card-overlay {
  position: absolute;
  background: linear-gradient(0deg, rgba(216, 41, 39, 0.9), rgba(216, 41, 39, 0.9));
  height: 350px;
  width: 350px;
  border: 1px solid white;
  border-radius: 30px;
  color: white;
  padding: 30px;
  opacity: 0; /* Start with opacity 0 (hidden) */
  visibility: hidden; /* Initially hidden */
  transform: translateY(-50px); /* Start 50px above the original position */
  transition: opacity 0.5s ease, visibility 0.5s ease, transform 0.5s ease; /* Smooth transition for opacity, visibility, and transform */
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.prd-card-overlay h2 {
  font-size: 28px;
  text-align: center;
}

.prd-card-overlay p {
  font-size: 15px;
  padding-top: 10px;
  text-align: center;
}

.prd-card-main img {
  height: 350px;
  width: 350px;
  border: 1px solid rgba(227, 227, 227, 1);
  box-shadow: 0px 0px 4px 0px rgba(160, 160, 160, 0.25);
  border-radius: 30px;
}

.prd-card-main h3 {
  font-size: 20px;
  font-weight: 600;
  color: rgba(48, 48, 48, 1);
}


/* @media screen and (max-width:1000px) {
  .prd-card-main img {
    height: 250px;
    width: 250px;
  }
} */

@media screen and (max-width:900px) {
  .product-list {
    justify-content: center;
    gap: 20px;
  }
}

@media screen and (max-width:756px){
  .prd-card-overlay h2{
    font-size:22px;
  }
  .prd-card-main h3{
    font-size:18px;
  }
}


/* left-content-right-image-main */



/* Footer */


/* About-us page */


/* product-card in product-line */
.product-cards-main {
  display: flex;
  row-gap: 80px;
  column-gap: 20px;
  margin: 100px auto;
  flex-wrap: wrap;
  justify-content: space-between;
  /* padding: 0 8rem; */
}

.prd-card {
  display: flex;
  flex-direction: column;
  align-items: center;
  background: white;
  box-shadow: 0px 4px 34px 0px rgba(0, 0, 0, 0.14);
  border-radius: 20px;
  overflow: hidden;
  width: 300px;
  position: relative;
}

.prd-card:hover .prd-description-overlay {
  visibility: visible;
  opacity: 1;
  transform: translateY(0); /* Move to original position */
}

.prd-description-overlay {
  position: absolute;
  background: linear-gradient(0deg, rgba(216, 41, 39, 0.9), rgba(216, 41, 39, 0.9));
  width: 300px;
  border: 1px solid white;
  border-radius: 20px;
  color: white;
  padding: 20px;
  height: 100%;
  opacity: 0;
  visibility: hidden;
  transform: translateY(-50px); /* Start 50px above the original position */
  transition: opacity 0.5s ease, visibility 0.5s ease, transform 0.5s ease; /* Smooth transition for opacity, visibility, and transform */
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.prd-description-overlay h4 {
  font-size: 26px;
  text-align: center;
}

.prd-description-overlay p {
  font-size: 15px;
  padding-top: 10px;
  text-align: center;
}

.prd-card img {
  width: 300px;
  height: 300px;
}

.prd-card h3 {
  font-size: 18px;
  font-weight: 400 !important;
  font-family: inter;
  color: white;
  padding: 25px;
  width: 100%;
  text-align: center;
  height: 70px;
  background: rgba(54, 54, 54, 1);
}

@media screen and (max-width:756px){
  .product-line-stanley-heading span{
    font-size: 26px;
  }
  .product-cards-main {
    margin:50px 0px !important;
  }
  .product-cards-main{
    row-gap:50px;
    justify-content: center;
  }

}


/* certificates */
.certificates {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-evenly;
  row-gap: 100px;
  padding: 2rem 4rem;
  margin-top: 50px;
}

.certificates img {
  max-width: 350px;
  width: 100%;
  border: 5px solid #D82927;
}

.certificate-dwnld-black-box {
  width: 100%;
  background: linear-gradient(0deg, #D82927, #D82927),
linear-gradient(90deg, rgba(0, 0, 0, 0.2) 0%, rgba(0, 0, 0, 0) 46%, rgba(0, 0, 0, 0.2) 100%);

  color: white;
  margin: auto;
  /* max-width: 900px; */
  padding: 10px;
  text-align: center;
  margin-top: 80px;
}
@media screen and (max-width:750px){
  .certificates{
    row-gap:30px;
  }
}
/* catalouges */
.catalouges-heading{
width: 100%;
margin:80px 0px;
/* text-align:center; */
}
.catalouges-heading h3{
  font-family: Poppins;
font-size: 40px;
font-weight: 700;
line-height: 60px;
color: #D82927;
width: max-content;
text-transform: uppercase;
position: relative;
/* text-align: center; */
margin: auto;
}
.catalouges-heading h3:before{
  content: "";
  position: absolute;
  left: 5px;
  bottom: -8px;
  width: 50%;
  border-bottom: 4px solid #D82927;
  border-radius: 10px;
}
.catalogue-certificates-list{
  display: flex;
  justify-content: center;
  margin:40px 0px;
  flex-wrap: wrap;
  column-gap: 70px;
  row-gap: 30px;
}
.ctlg-card-wrapper{
  background-color: rgba(247, 247, 247, 1);
  border-radius:20px;
  box-shadow: 0px 4px 34px 0px rgba(0, 0, 0, 0.13);
  overflow: hidden;
  margin-bottom: 30px;
}

.ctlg-card{
      display: flex;
    flex-direction: column;
    align-items: center;
}
.ctlg-card-wrapper img{
  max-width: 300px;
}
.ctlg-card-wrapper h3{
  text-align: center;
  padding: 15px;
  font-family: Poppins;
font-size: 24px;
font-weight: 600;
}
.ctlg-dwnld-btn{
     padding: 10px 30px;
    border-radius: 40px;
    background: rgba(216, 41, 39, 1);
    color: white;
    text-decoration: none;
}
.ctlg-dwnld-btn:hover{
  color: #D82927;
  background-color: #ffffff;
  outline: 1px solid #D82927;
}
@media screen and (max-width:756px){
  .catalouges-heading h3{
    font-size:28px;
  }
  .ctlg-card-wrapper h3{
    font-size:18px;
  }
}

/* contact-us-main */
.cont-us-details h2 {
  font-size: 48px;
  text-align: center;
  font-weight: 900;
  padding: 100px 0px 40px 0px;
}

.addres-container {
  max-width: 730px;
  margin: auto;
  display: flex;
  justify-content: space-between;
  align-items:center;
  margin: 20px auto 40px auto;
  gap: 20px;
  padding: 30px;
  border-radius: 30px;
  background-color: rgba(248, 248, 248, 1);
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);

}

.detail-content h3 {
  font-size: 20px;
  font-weight: 400 !important;
  padding-bottom: 15px;
}

.detail-content p {
  font-size: 15px;
  font-weight: 500;
  color: rgba(110, 110, 110, 1);
}

.fa-location-dot,
.fa-phone,
.fa-envelope {
  font-size: 75px;
    color: #de4d4c;
}

@media screen and (max-width:756px){
  .fa-location-dot, .fa-phone, .fa-envelope{
    font-size:45px;
  }
}

.slick-dots {
  bottom: 4% !important;
}
.slick-prev,
.slick-next{
  top:60% !important;
}
.slick-dots li button:before {
  font-size: 12px !important;
}

.slick-dots li.slick-active button:before {
  color: rgba(216, 41, 39, 1) !important;
  opacity: 1 !important;
  /* border:1px solid rgba(216, 41, 39, 1); */
}

.slick-dots li button:before {
  color: transparent !important;
  opacity: 1 !important;
  color: #ffffff !important;
}

.slick-prev {
  left: 1% !important;
  height: 25px !important;
  width: 25px !important;
  z-index: 2;
}

.slick-next {
  right: 1% !important;
  height: 25px !important;
  width: 25px !important;
  z-index: 2;

}

.contact-form-form {
  padding-top: 20px;
  padding-bottom: 100px;
}

.form-field-box {
  display: flex;
  flex-direction: column;
}

.grid-fields {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 30px;
}

.grid-fields input {
  padding: 10px;
  border: 1px solid rgba(85, 85, 85, 0.54);
  border-radius:10px;
}

.main-form {
  background: rgba(248, 248, 248, 1);
}

.main-form label,
input,
textarea {
  font-size: 18px;
  /* border-radius: 10px;; */
}

.main-form h2 {
  font-size: 48px;
  text-align: center;
  font-weight: 800;
  padding-top: 60px;
  padding-bottom: 60px;
  text-transform: uppercase;
}

.cntc-form-inq {
  padding-top: 20px;
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.y-i-a-text {
  width: 100%;
  border-bottom: 1px solid rgba(85, 85, 85, 0.54);
  padding-bottom: 10px;
}

.text-area-cnt-frm {
  width: 100%;
  padding: 10px;
  margin-top: 10px;
  margin-bottom: 10px;
}

input[type="checkbox"] {
  accent-color: rgb(216, 41, 39);
  height: 20px;
  width: 20px;
}

.cntc-form-inq {
  margin-bottom: 10px;
}

.cntc-form-inq label {
  display: flex;
  align-items: center;
  gap: 10px;
  padding: 10px 0px;
}

.form-submit-button {
  background-color: rgb(216, 41, 39);
  padding: 10px 20px;
  font-size: 18px;
  color: white;
  border-radius:10px;
  margin: 20px 0px;
  border: none;
}

textarea{
  border-radius: 10px;
}
.main-form {

  margin-top: 100px;
}

@media screen and (max-width:750px) {
  .grid-fields {
    grid-template-columns: 1fr;
  }
  .cont-us-details h2,.main-form h2{
    font-size:28px ;
    padding-top:35px;
    padding-bottom:10px;
  }
}



/* banner_image */




.logo-slider-main{
  padding:40px 0px;
}

.logo-slider-main .slick-slider{
  padding:0 45px;
}
.slider-wrapper-logo {
  display: flex !important;
  justify-content: center;
  align-items: center;
  height: 150px;;
}

@media screen and (max-width:756px){
  .logo-slider-main{
    padding:0px 0px 20px 0px;
  }
}









