.banner_image{
    width: 100%;
    height: 500px;
    margin-top: 120px;;
  }
  .banner_image img{
    
    height: 100%;
    width: 100%;
    border-radius:30px;
  }

  .mobile_image{display:none;}
  @media screen and (max-width:750px){
    .desktop_image{display:none;}
    .mobile_image{display:block;}
    .banner_image{height: 300px;background-position: left !important;}
    
  }
  
  .mission_images{
    border-radius: 40px;
    height: 500px;
    margin: 20px 0px;
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
  }
  .heading-subheading-image-desc{
    padding: 40px 0px;
  }
  @media screen and (max-width:750px){
    .heading-subheading-image-desc{
    padding:30px 0px 15px 0px ;
    }
      .heading-subheading-image-desc img{
          border-radius:0px;
      }
      .mission_images{
        height: 200px;
      }
  }
  .heading-subheading-image-desc h3{
    font-size: 32px;
      font-family: 'Poppins';
      color: #D82927;
  }
  @media screen and (max-width:756px){
    .heading-subheading-image-desc h3{
      font-size:20px;
    }
    .heading-subheading-image-desc{
      padding-top:20px;
    }
  }
  .heading-subheading-image-desc span{
    color: black;
  }
  
  .heading-subheading-image-desc img{
    width: 100%;
      border-radius: 60px;
      padding: 20px 0px;
  }
  
  .heading-subheading-image-desc p{
    font-size: 16px;
    color: rgba(48, 48, 48, 1);
    max-width:1000px;
  }
  
  
  
  
  
  /* Banner image */
  
  .banner-heading{
        /* z-index: 10; */
        display: flex;
        justify-content: center;
        align-items: center;
        height: 100%;
        max-width: 310px;
        font-size: 48px;
        padding-left: 50px;
  }
  @media screen and (max-width:756px){
    .banner-heading{
      font-size:32px;
      max-width:100%;
      padding-left:0;
    }
  }
